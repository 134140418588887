import type { Theme } from "@wind/ui/theme";

export const WINDMILL_LIGHT_THEME: Theme = {
  colors: {
    accent: "#17181c",
    highlight: "#3b82f6",
    background: {
      base: "#f5f7fa",
      surface: "#ffffff",
      surfaceMuted: "#f1f5f9",
    },
    text: {
      primary: "#0f172a",
      secondary: "#334155",
      tertiary: "#64748b",
      inverse: "#f8fafc",
    },
    border: {
      default: "#e2e8f0",
      strong: "#cbd5e1",
    },
    sidebar: {
      background: "#ffffff",
      foreground: "#334155",
      primary: "#f1f5f9",
      primaryForeground: "#0f172a",
      accent: "#f1f5f9",
      accentForeground: "#0f172a",
      border: "#cbd5e1",
      ring: "#3b82f6",
    },
    green: {
      background: "#D0F4D9",
      foreground: "#20522D",
    },
    red: {
      background: "#fee2e2",
      foreground: "#b91c1c",
    },
    yellow: {
      background: "#fefce8",
      foreground: "#ca8a04",
    },
  },
  radius: 0.2,
  borderWidth: 1,
};

export const WINDMILL_DARK_THEME: Theme = {
  colors: {
    accent: "#fafafa",
    highlight: "#fafafa",
    background: {
      base: "#09090b",
      surface: "#18181b",
      surfaceMuted: "#27272a",
    },
    text: {
      primary: "#fafafa",
      secondary: "#e5e7eb",
      tertiary: "#d4d4d8",
      inverse: "#09090b",
    },
    border: {
      default: "#3f3f46",
      strong: "#71717a",
    },
    sidebar: {
      background: "#18181b",
      foreground: "#cbd5e1",
      primary: "#334155",
      primaryForeground: "#e5e7eb",
      accent: "#3f3f46",
      accentForeground: "#fafafa",
      border: "#3f3f46",
      ring: "#3b82f6",
      logo: "#ffffff",
    },
    green: {
      background: "#052e16",
      foreground: "#bbf7d0",
    },
    red: {
      background: "#450a0a",
      foreground: "#fecaca",
    },
    yellow: {
      background: "#422006",
      foreground: "#fef9c3",
    },
  },
  radius: 0.2,
  borderWidth: 1,
};

export const ADMIN_THEME: Theme = {
  colors: {
    accent: "#17181c",
    highlight: "#3b82f6",
    background: {
      base: "#f5f7fa",
      surface: "#ffffff",
      surfaceMuted: "#f1f5f9",
    },
    text: {
      primary: "#0f172a",
      secondary: "#334155",
      tertiary: "#64748b",
      inverse: "#f8fafc",
    },
    border: {
      default: "#e2e8f0",
      strong: "#cbd5e1",
    },
    sidebar: {
      background: "#e2e8f0",
      foreground: "#334155",
      primary: "#cdd5df",
      primaryForeground: "#0f172a",
      accent: "#ccd3d9",
      accentForeground: "#0f172a",
      border: "#8f9bab",
      ring: "#3b82f6",
      logo: "#3b82f6",
    },
    green: {
      background: "#D0F4D9",
      foreground: "#20522D",
    },
    red: {
      background: "#fee2e2",
      foreground: "#b91c1c",
    },
    yellow: {
      background: "#fefce8",
      foreground: "#ca8a04",
    },
  },
  radius: 0.2,
  borderWidth: 1,
};
